<template>
  <button
    class="btn-table-action"
    type="button"
    :data-test="`btn-table-action-${icon}`"
    :title="getTitle"
    @click="$emit('click', $event)"
  >
    <component
      :is="iconComponentName"
      class="btn-table-action__icon"
    />
  </button>
</template>

<script>
import IconCopy from '@/assets/img/svg/copy.svg?inline'
import IconInfo from '@/assets/img/svg/info.svg?inline'
import IconEdit from '@/assets/img/svg/edit.svg?inline'
import IconPreview from '@/assets/img/svg/preview.svg?inline'
import IconDelete from '@/assets/img/svg/delete.svg?inline'
import IconCheck from '@/assets/img/svg/check-solid.svg?inline'
import IconCancel from '@/assets/img/svg/cancel.svg?inline'

export default {
  name: 'ButtonTableAction',
  props: {
    icon: {
      required: true,
      validator: function (value) {
        return ['copy', 'duplicate', 'info', 'edit', 'preview', 'delete', 'check', 'cancel'].includes(value)
      }
    },
    title: {
      type: String,
      default: undefined
    }
  },
  components: {
    IconCopy,
    IconDuplicate: IconCopy,
    IconInfo,
    IconEdit,
    IconPreview,
    IconDelete,
    IconCheck,
    IconCancel
  },
  computed: {
    iconComponentName () {
      const iconName = this.icon.charAt(0).toUpperCase() + this.icon.slice(1)
      return `Icon${iconName}`
    },
    getTitle () {
      return this.title === undefined ? this.$t(`buttons.${this.icon}`) : this.title
    }
  }
}
</script>

<style scoped lang="scss">
.btn-table-action {
  background: #fff;
  width: rem(30px);
  height: rem(30px);
  border-radius: rem(4px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid #6599FE;
    .btn-table-action__icon {
      fill: #6599FE;
    }
  }
  &__icon {
    width: rem(16px);
    height: auto;
    fill: #9DAAC4;
  }
}
</style>
