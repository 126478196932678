<template>
  <div class="abstract-data-table__td" :style="columnStyle">
    <slot name="before" />
    <span v-if="titleHeader" class="abstract-data-table__td-desc">{{ titleHeader }}</span>
    <span :class="{
      'abstract-data-table__td-title': true,
      'abstract-data-table__td-title--subtitle': subtitle
    }">
      <span v-if="titleValueOneLine" class="one-line" :title="titleValue">{{ titleValue }}</span>
      <span v-else>{{ titleValue }}</span>
    </span>

  </div>
</template>

<script>
export default {
  name: 'TitleColumn',
  props: {
    titleHeader: {
      type: String,
      required: true
    },
    titleValue: {
      type: [String, Number],
      default: ''
    },
    titleValueOneLine: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: Boolean,
      default: false
    },
    columnStyle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
  .one-line {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
