<template>
  <section class="video-filter">
    <form class="video-filter__form" @submit.prevent="filterVideos">
      <div class="video-filter__form-panel-1">
        <div class="filter-row four-columns-grid">
          <div>
            <label>{{ $t('article.filter.search_in') }}</label>
            <input
              type="radio"
              v-model="filter.searchIn"
              value="title"
              id="fulltext_title"
              class="with-gap"
              checked
            >
            <label class="radio-inline" for="fulltext_title">
              {{ $t('video.filter.title') }}
            </label>
            <input
              type="radio"
              v-model="filter.searchIn"
              value="keywords"
              id="fulltext_keywords"
              class="with-gap"
              checked
            >
            <label class="radio-inline" for="fulltext_keywords">
              {{ $t('video.filter.keywords') }}
            </label>
            <input
              type="radio"
              v-model="filter.searchIn"
              value="description"
              id="fulltext_description"
              class="with-gap"
            >
            <label class="radio-inline" for="fulltext_description">
              {{ $t('video.filter.description') }}
            </label>
            <Input
              v-model="filter.title"
              id="filter_search"
              label="Search"
              no-label
              disable-form-group
            />
          </div>
          <Input
            v-model="filter.videoId"
            id="filter_bodyText"
            :label="$t('video.filter.id')"
          />
          <Datepicker
            class="filter-row__date"
            type="date"
            v-model="filter.dateFrom"
            id="filter_dateFrom"
            :label="$t('video.filter.date_from')"
            disable-form-group
          />
          <Datepicker
            class="filter-row__date"
            type="date"
            v-model="filter.dateTo"
            id="filter_dateTo"
            :label="$t('video.filter.date_to')"
            disable-form-group
          />
        </div>
        <template v-if="advancedFilter">
          <div class="filter-row" :class="!vlm ? 'five-columns-grid' : 'three-columns-grid'">
            <SiteSelect
              v-if="vlm"
              v-model="filter.defaultSite"
              :options="sites"
              :label="$t('video.filter.default_site')"
              id="filter_defaultSite"
            />
            <Select
              v-else
              v-model="filter.defaultSite"
              :options="sites"
              :label="$t('video.filter.default_site')"
              id="filter_defaultSite"
              disable-form-group
            />
            <MultiSelect
              v-if="!vlm"
              v-model="filter.authorUsersDepartments"
              :options="departments"
              :label-desc="$t('beUser.department')"
              label="name"
              track-by="id"
              id="filter_author_users_departments"
            />
            <MultiSelect
              v-if="!vlm"
              v-model="filter.videoShows"
              :options="videoShows"
              :label-desc="$t('video.filter.video_show')"
              label="title"
              track-by="id"
              id="filter_videoShows"
            />
            <MultiSelect
              v-model="filter.authorUsers"
              :options="users"
              :label-desc="$t('video.filter.author')"
              label="username"
              track-by="id"
              id="filter_author_users"
            />
            <MultiSelect
              v-model="filter.cameraUsers"
              :options="users"
              label="username"
              :label-desc="$t('video.filter.camera')"
              track-by="id"
              id="filter_camera_users"
            />
          </div>
          <div class="filter-row five-columns-grid">
            <Select
              v-model="filter.type"
              :options="videoTypeValues"
              :label="$t('video.filter.type')"
              id="filter_type"
              disable-form-group
            />
            <Select
              v-model="filter.status"
              :options="videoStatusValues"
              :label="$t('video.filter.status')"
              id="filter_status"
              disable-form-group
            />
            <div>
              <label>{{ $t('video.video_nature') }}</label>
              <Checkbox
                v-model="filter.setting_editorialContent"
                id="video_setting_editorialContent"
                :label="$t('video.filter.setting_editorial_content')"
              />
              <Checkbox
                v-model="filter.setting_agencyContent"
                id="video_setting_agencyContent"
                :label="$t('video.filter.setting_agency_content')"
              />
              <Checkbox
                v-model="filter.setting_marketingContent"
                id="video_setting_marketingContent"
                :label="$t('video.filter.setting_marketing_content')"
              />
            </div>
            <div>
              <label>{{ $t('video.video_source') }}</label>
              <Checkbox
                v-model="filter.setting_social"
                id="video_setting_social"
                :label="$t('video.filter.setting_social')"
              />
              <Checkbox
                v-model="filter.setting_camera"
                id="video_setting_camera"
                :label="$t('video.filter.setting_camera')"
              />
              <Checkbox
                v-model="filter.setting_slideshow"
                id="video_setting_slideshow"
                :label="$t('video.filter.setting_slideshow')"
              />
            </div>
            <div>
              <label></label>
              <Checkbox
                v-model="filter.setting_agency"
                id="video_setting_agency"
                :label="$t('video.filter.setting_agency')"
              />
              <Checkbox
                v-model="filter.setting_mobilePhone"
                id="video_setting_mobilePhone"
                :label="$t('video.filter.setting_mobile_phone')"
              />
              <Checkbox
                v-model="filter.setting_show"
                id="video_setting_show"
                :label="$t('video.filter.setting_show')"
              />
            </div>
          </div>
        </template>
      </div>
      <div class="video-filter__form-panel-2" >
        <div class="video-filter__form-panel-2__filter-buttons">
          <ButtonSearch />
          <ButtonShowAdvancedFilter
            :on-click="toggleAdvancedFilter"
            :is-enabled="advancedFilter"
            color-enabled
          />
          <div class="video-filter__separator-vertical" />
          <div class="filter-row two-columns-grid-with-reset">
            <ButtonResetFilter class="video-filter__form-panel-2__reset-button" :on-click="resetFilters" />
            <ButtonCreate v-if="showCreateButton" :route-name="`video_new${getVideoRoute}`" />
          </div>
        </div>
        <template v-if="advancedFilter">
          <div class="filter-row one-column-grid">
            <MultiSelect
              v-model="filter.editingUsers"
              :options="users"
              :label-desc="$t('video.filter.editing')"
              label="username"
              track-by="id"
              id="filter_editing_users"
            />
          </div>
          <div class="filter-row">
            <div>
              <label></label>
              <Checkbox
                v-model="filter.setting_forAdults"
                id="video_setting_for_adults"
                :label="$t('video.filter.setting_for_adults')"
              />
              <Checkbox
                v-model="filter.setting_productPlacement"
                id="video_setting_product_placement"
                :label="$t('video.filter.setting_product_placement')"
              />
              <Checkbox
                v-model="filter.setting_exclusive"
                id="video_setting_exclusive"
                :label="$t('video.filter.setting_exclusive')"
              />
            </div>
          </div>
        </template>
      </div>
    </form>
  </section>
</template>
<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import Datepicker from '@/components/form/Datepicker'
import Checkbox from '@/components/form/Checkbox'
import VideoFilter from '../../model/VideoFilter'
import VideoTypeMixin from '@/components/mixins/valueObject/VideoTypeMixin'
import VideoStatusMixin from '@/components/mixins/valueObject/VideoStatusMixin'
import ButtonSearch from '@/components/buttons/ButtonSearch'
import ButtonResetFilter from '@/components/buttons/ButtonResetFilter'
import ButtonShowAdvancedFilter from '@/components/buttons/BtnArticleShowAdvancedFilter'
import ButtonCreate from '@/components/shared/ButtonCreate'
import { mapGetters } from 'vuex'
import { MODULE_VIDEO } from '@/model/ValueObject/UserPermissionModules'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'VideoFilter',
  mixins: [VideoTypeMixin, VideoStatusMixin],
  props: {
    showCreateButton: {
      type: Boolean,
      default: true
    },
    simpleTableList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(VideoFilter)
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    getVideoRoute () {
      if (this.$route && this.$route.name.includes('old')) {
        return '_old'
      } else {
        return ''
      }
    },
    departments () {
      return this.$store.getters['department/all']
    },
    users () {
      return this.$store.getters['user/all']
    },
    sites () {
      return this.$store.getters['site/userSites'](MODULE_VIDEO)
    },
    videoShows () {
      return this.$store.getters['videoShow/all']
    },
    advancedFilter () {
      return this.$store.getters['video/advancedFilter']
    }
  },
  components: {
    SiteSelect,
    Input,
    Select,
    MultiSelect,
    Datepicker,
    Checkbox,
    ButtonSearch,
    ButtonResetFilter,
    ButtonShowAdvancedFilter,
    ButtonCreate
  },
  methods: {
    filterVideos () {
      this.$store.commit('video/setPage', 1)
      this.$store.commit('video/setFilter', this.filter)
      if (this.simpleTableList) {
        this.$store.dispatch('video/fetch')
      } else {
        this.$store.dispatch('video/fetchPage')
      }
      const siteName = this.$store.getters['site/getSiteNameById'](this.filter.defaultSite)
      this.$gtm.trackView(this.$route.name, siteName)
    },
    resetFilters () {
      this.filter = this._.cloneDeep(VideoFilter)
      this.filterVideos()
    },
    getVideoShows () {
      this.$store.dispatch('videoShow/fetchAll')
    },
    toggleAdvancedFilter () {
      this.$store.commit('video/toggleAdvancedFilter')
    },
    checkAdvancedFilter () {
      if (
        (this.filter.type !== '' && this.filter.type !== '0') ||
        (this.filter.status !== '' && this.filter.status !== '0') ||
        this.filter.defaultSite ||
        this.filter.videoShows.length > 0 ||
        this.filter.authorUsers.length > 0 ||
        this.filter.authorUsersDepartments.length > 0 ||
        this.filter.cameraUsers.length > 0 ||
        this.filter.editingUsers.length > 0 ||
        this.filter.setting_forAdults ||
        this.filter.setting_productPlacement ||
        this.filter.setting_exclusive ||
        this.filter.setting_editorialContent ||
        this.filter.setting_agencyContent ||
        this.filter.setting_marketingContent ||
        this.filter.setting_internal ||
        this.filter.setting_social ||
        this.filter.setting_agency ||
        this.filter.setting_camera ||
        this.filter.setting_mobilePhone ||
        this.filter.setting_slideshow ||
        this.filter.setting_show
      ) {
        this.$store.commit('video/toggleAdvancedFilter', true)
      }
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['video/filter']
    this.checkAdvancedFilter()
  },
  created () {
    if (!this.vlm) {
      this.getVideoShows()
    }
  }
}
</script>

<style lang="scss">
.video-filter {
  display: grid;
  margin-bottom: 1rem;
  &__form {
    display: grid;
    grid-gap: rem(20px);
    grid-auto-flow: dense;
    @include bp(0 11) {
      grid-template-columns: 1fr;
    }
    @include bp(12) {
      grid-template-columns: 75% max-content;
    }
  }
  &__form-panel-1 {
    .filter-row {
      margin-top: 1rem;
      display: grid;
      grid-gap: rem(10px);
      &__date {
        margin-top: rem(3px);
      }
    }

    @include bp(0 11) {
      .three-columns-grid, .four-columns-grid, .five-columns-grid {
        grid-template-columns: 1fr;
      }
    }
    @include bp(12) {
      .three-columns-grid {
        grid-template-columns: 1fr 1fr 1fr;
      }
      .four-columns-grid {
        grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
      }
      .five-columns-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }
  &__form-panel-2 {
    margin-top: rem(40px);
    &__filter-buttons {
      display: grid;
      grid-template-columns: max-content max-content max-content max-content max-content;
      grid-gap: rem(10px);
    }
    .filter-row {
      display: grid;
      grid-gap: rem(10px);
    }
    .one-column-grid {
      margin-top: rem(34px);
      margin-bottom: rem(16px);
    }
    @include bp(0 11) {
      order: -1;
    }
    @include bp(0 15) {
      .two-columns-grid-with-reset {
        margin-top: 0;
        grid-template-columns: 1fr;
      }
    }
    @include bp(16) {
      .two-columns-grid-with-reset {
        margin-top: 0;
        grid-template-columns: 1fr max-content;
      }
    }
    &__reset-button {
      width: max-content;
    }
  }
  &__separator-vertical {
    margin: 0 rem(10px);
    background: #D1DBE4;
    width: rem(1px);
    height: rem(40px);
  }
}

</style>
